<template>
  <div>
    <div class="container">
      <div class="header"><router-link to="/myaccount"><img src="../assets/images/back.png" alt=""></router-link> 个人资料</div>
      <div class="blian22"></div>
    </div>
    <div class="account-list">
      <ul>
        <li class="person_head">
          <span>头像</span>
          <div class="alright">
            <em>
            	<img :src="info.headPhoto" v-if="!editImg">
            	<img :src="basUrl+info.headPhoto" v-else>
              <input type="file" @change="uploadBefore" name="file" style="width: 2.2rem; height: 2.2rem;position: absolute;left: 0;opacity: 0;margin-top: 0;" />
            </em>
            <img src="../assets/images/right.png" alt="" class="rightImg">
          </div>
        </li>
        <li>
          <span>昵称内容</span>
          <div class="alright">
            <input type="text" placeholder="昵称内容" v-model="info.username">
            <img src="../assets/images/right.png" alt="" class="rightImg">
          </div>
        </li>
        <li>
          <span>性别</span>
          <div class="alright">
            <select v-model="info.gender">
                <option v-for="(item,index) in sexArr"  :value="item.sex" :key="index">{{ item.name }}</option>
            </select>
            <img src="../assets/images/right.png" alt="" class="rightImg">
          </div>
        </li>
        <li>
          <span>生日</span>
          <div class="alright" @click="openPicker">
            <em>{{ info.birthday }}</em>
            <img src="../assets/images/right.png" alt="" class="rightImg">
          </div>
        </li>
        <li>
          <router-link to="/myaddress">
            <span>收货地址</span>
            <div class="alright">
              <img src="../assets/images/right.png" alt="" class="rightImg">
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="cont_foot_btn" @click="submit">
			<a>保存</a>
		</div>
		 <mt-datetime-picker
    type="date"
                ref="picker"
                year-format="{value} 年"
                month-format="{value} 月"
                date-format="{value} 日"
    :startDate='startDate'
    @confirm="confirm"
    v-model="pickerValue">
  </mt-datetime-picker>
  </div>
</template>
<script>
	import { userInfo,uploadPic,modifyInfo } from "@/assets/js/api";
	import { DatetimePicker,Picker,Toast } from 'mint-ui';
	import { formatDate } from '../assets/js/brd.js';
export default {
  name: "mypersonal",
  data() {
    return {
    	info:{},
    	editImg:false,
    	startDate: new Date('1968-01-01'),
    	pickerValue:'',
    	sexArr:[
    	  {sex:1,name:'男'},
    	  { sex:2,name:'女' }
    	]
    }
  },
  methods: {
  	openPicker() {
        this.$refs.picker.open();
     },
  	confirm(value){
  		this.info.birthday = formatDate(value,'yyyy-MM-dd');
  		this.$forceUpdate();
  	},
  	//获取用户信息
			getuserInfo(){
				userInfo({}).then(res => {
					if(res.code == 0) {
						this.info = res.userInfo;
					}
				});
			},
			//上传图片
			uploadBefore(e) {
				e.preventDefault();
				let files = event.target.files;
				let formData = new FormData();
					formData.append('dir', 'other');
					formData.append('token',localStorage.getItem('token'));
					formData.append('pic', files[0]);
					uploadPic(formData).then(res => {
						if(res.code == 0) {
							this.editImg = true;
							this.info.headPhoto = res.picPath;
							this.$forceUpdate();
						}
					});
			},
			
			submit(){
				let params = {
					username:this.info.username,
					gender:this.info.gender,
					birthday:this.info.birthday,
					headPhoto:this.info.headPhoto,
				}
				modifyInfo(params).then(res => {
					if(res.code == 0) {
						Toast({
						  message: '保存成功',
						  position: 'middle',
						  duration: 1000
						});
						this.editImg = false;
						this.getuserInfo()
					}
				});
			}
  },
  mounted() {
  	this.getuserInfo()
  }
};
</script>

